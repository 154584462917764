import React, { useState } from 'react'
import PopupModel from '../../model/PopupModel';

export const PaymentPlan = () => {
     const [showPopup, setShowPopup] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(true);

     const handleViewPlanClick = () => {
       setShowPopup(true);
     };

     const handleFormSubmit = () => {
       setIsOverlayVisible(false);
       setShowPopup(false); // Optionally close the popup after submission
     };
    
  return (
    <div className="relative overflow-hidden">
      {isOverlayVisible && (
        <div
          onClick={handleViewPlanClick}
          className="w-full h-[100%] absolute backdrop-blur-sm cursor-pointer flex items-center justify-center"
        >
          <div className="relative">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <button className="px-9 py-2 bg-sky-400 rounded-full  m-auto text-[#000] ">
              View Payment Plan
            </button>
          </div>
        </div>
      )}
      <table className="w-full border-2 overflow-auto border-[#fff] ">
        <thead>
          <tr className="bg-[#fff]">
            <th>Description</th>
            <th>Milestone Event</th>
            <th>(%) Value</th>
          </tr>
        </thead>
        <tbody className="text-[#fff] capitalize">
          <tr>
            <td>Deposit</td>
            <td>Immediate</td>
            <td>20</td>
          </tr>
          <tr>
            <td>1st installment</td>
            <td>With 3 months of sale Date</td>
            <td>5</td>
          </tr>
          <tr>
            <td>2nd installment</td>
            <td>With 6 months of sale Date</td>
            <td>5</td>
          </tr>
          <tr>
            <td>3rd installment</td>
            <td>with 9 months of sale Date</td>
            <td>5</td>
          </tr>
          <tr>
            <td>4th installment</td>
            <td>with 12 months of sale Date</td>
            <td>5</td>
          </tr>
          <tr>
            <td>5th installment</td>
            <td>with 15 months of sale Date</td>
            <td>3</td>
          </tr>
          <tr>
            <td>6th installment</td>
            <td>with 18 months of sale Date</td>
            <td>3</td>
          </tr>
          <tr>
            <td>7th installment</td>
            <td>with 18 months of sale Date</td>
            <td>3</td>
          </tr>
          <tr>
            <td>8th installment</td>
            <td>with 24 months of sale Date</td>
            <td>3</td>
          </tr>
          <tr>
            <td>9th installment</td>
            <td>with 27 months of sale Date</td>
            <td>3</td>
          </tr>
          <tr>
            <td>10th installment</td>
            <td>with 30 months of sale Date</td>
            <td>3</td>
          </tr>
          <tr>
            <td>11th installment</td>
            <td>with 33 months of sale Date</td>
            <td>2</td>
          </tr>
          <tr>
            <td>12th installment</td>
            <td>on completion</td>
            <td>40</td>
          </tr>
        </tbody>
      </table>
      <p className="text-center mt-3 mb-8">
        4% Dubai Land Department will be paid in additional to the Initial
        Deposit
      </p>
      <div>
        {showPopup && (
          <PopupModel
            onClose={() => setShowPopup(false)}
            onFormSubmit={handleFormSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default PaymentPlan